import React from "react";

//styles
import s from "./ListItem2.module.scss";


/*
 * contents=[{
   icon = font-awesome classes
   image? = <img />
   heading = string in h4
   content = string in p
   link? = <tag />
 }]
 * columnPerRow = number of columns per row, total data being contents above is 9 objects with 3 columns per row = 3 x 3 grid
 * hasCircle = flag for circle, default true
 * circleColor = color of circle, default nitco purple
 * rootClassName = class for whole panel
 * rootStyle = style for whole panel
 * itemClassName = class for every item
 * itemStyle = style for every item
 */
const ListItemComponent = ({
  //background-layer
  rootClassName,
  rootStyle,

  //content object layer
  itemClassName,
  itemStyle,

  contents,

  //number of rows
  columnsPerRow,

  //circle
  hasCircle,
  circleColor,
  circleBorderColor,



  headingContainerClassName,
}) => {
  const numberOfRows = Math.ceil(contents.length / columnsPerRow);
  let columnStyle = ` mb-5 `;
  let counter = columnsPerRow * -1;

  const circleStyle = {};

  if (circleColor) {
    circleStyle.backgroundColor = circleColor;
  }

  if (circleBorderColor) {
    circleStyle.borderColor = circleBorderColor;
  }

  switch (columnsPerRow) {
    case 1:
      columnStyle += "col-sm-12";
      break;
    case 2:
      columnStyle += "col-md-6";
      break;
    case 3:
      columnStyle += `col-lg-4 col-md-12`;
      break;
    case 4:
      columnStyle += `col-lg-3 col-md-6`;
      break;
  }

  return (
    <>
      {/* <PanelFoundation
			rootClassName={`panel-padding ${rootClassName || ""}`}
			rootStyle={rootStyle}
		>
		
		</PanelFoundation> */}
      <div
        className={`container-fluid panel-padding ${rootClassName || ""}`}
        rootStyle={rootStyle}
      >
        <div className={`container`}>
          {[...Array(numberOfRows)].map((e, i) => {
            counter += columnsPerRow;

            return (
              <div className={`row`} key={i}>
                {contents
                  .slice(counter, counter + columnsPerRow)
                  .map((content, index) => {
                    return (
                      <React.Fragment key={index}>
                        {/* {renderTopItem(params)} */}
                        <div className={`${columnStyle}`}>
                          <div className={`card ${s.card} ${s.panel}`} style={itemStyle}>
                            <div className={`card-header ${s.inline}`}>
                              <div
                                className={`${hasCircle ? s.iconCircle : ""} ${headingContainerClassName}`}
                                style={hasCircle ? circleStyle : {}}
                              >
                                {content.icon && <i className={content.icon} />}
                                {content.image}
                              </div>
                              <div className={`${s.title}`}>
                                <h4>{content.heading}</h4>
                              </div>
                            </div>
                            <div className={`card-body ${itemClassName} ${s.verticalContent} `}>
                              <div>
                                <div>{content.content}</div>
                              </div>
                              {content.link && <div>{content.link}</div>}</div>
                          </div>
                        </div>
                      </React.Fragment>
                    );
                  })}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default ListItemComponent;
