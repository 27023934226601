import React, { useState } from "react";
import { graphql } from "gatsby";
import Image from "gatsby-image";
import { Col } from "react-bootstrap";
import ReactMarkdown from "react-markdown/with-html";
import { LinkRenderer } from "../../utils/utilities";
import contactStyle from "../../components/non-panels/ContactForm.module.scss";

//components
import PanelFoundation from "../../components/panels/PanelFoundation";
import HubSpotForm from "../../components/non-panels/forms/HubSpotForm";
import Hero from "../../components/panels/Hero";
// import Approach from "../../components/panels/Approach";
// import MoreCards from "../../components/panels/MoreCards";
import ComponentHeader from "../../components/panels/ComponentHeader";
import SwitchComponent from "../../components/panels/Switch";
// import ListItem from "../../components/panels/list-item";
import useSetPageData from "../../utils/useSetPageData";
import ListItem from "../../components/panels/ListItem";
import ListItem2 from "../../components/panels/ListItem2";
import SEO from "../../components/seo";
import { Modal } from "react-bootstrap";
//images
import TempImage from "../../images/icons_new/warehousing_1.png";

//image
import RPA_Support_Section_1_Automation_optimization from "../../images/manage-rpa/RPA_Support_Section_1_Automation_optimization.png";
import RPA_Support_Section_1_Business_hours_only_or_24_7_365 from "../../images/manage-rpa/RPA_Support_Section_1_Business_hours_only_or_24-7-365.png";
import RPA_Support_Section_1_Consistent_and_transparent from "../../images/manage-rpa/RPA_Support_Section_1_Consistent_and_transparent.png";
import RPA_Support_Section_1_Defined_Service_Level from "../../images/manage-rpa/RPA_Support_Section_1_Defined_Service_Level.png";
import RPA_Support_Section_1_Enhancement_development from "../../images/manage-rpa/RPA_Support_Section_1_Enhancement_development.png";
import RPA_Support_Section_1_On_boarding_and_support_for_RPA from "../../images/manage-rpa/RPA_Support_Section_1_On-boarding_and_support_for_RPA.png";
import RPA_Support_Section_1_Proactive_monitoring from "../../images/manage-rpa/RPA_Support_Section_1_Proactive_monitoring.png";
import RPA_Support_Section_1_RPA_Managed_Services_Can_Reduce from "../../images/manage-rpa/RPA_Support_Section_1_RPA_Managed_Services_Can_Reduce.jpg";
import RPA_Support_Section_1_Scalable_pricing from "../../images/manage-rpa/RPA_Support_Section_1_Scalable_pricing.png";
import RPA_Support_Section_1_Why_Your_Organization_Needs_RPA from "../../images/manage-rpa/RPA_Support_Section_1_Why_Your_Organization_Needs_RPA.jpg";
import RPA_Support_Section_2_Customer_portal_to_support_and_maintenance from "../../images/manage-rpa/RPA_Support_Section_2_Customer_portal_to_support_and_maintenance.png";
import RPA_Support_Section_2_Monitoring_your_automations_for_errors from "../../images/manage-rpa/RPA_Support_Section_2_Monitoring_your_automations_for_errors.png";
import RPA_Support_Section_2_Processes_for_reporting_issues_and_tracking from "../../images/manage-rpa/RPA_Support_Section_2_Processes_for_reporting_issues_and_tracking.png";
import RPA_Support_Section_2_Working_collaboratively_to_scale_up from "../../images/manage-rpa/RPA_Support_Section_2_Working_collaboratively_to_scale_up.png";
import RPA_Support_Section_3_Cloud from "../../images/manage-rpa/RPA_Support_Section_3_Cloud.png";
import RPA_Support_Section_3_On_Prem from "../../images/manage-rpa/RPA_Support_Section_3_On-Prem.png";
import RPA_Support_Section_4_Production_RPA_Maintenance_Lights_On_Incident from "../../images/manage-rpa/RPA_Support_Section_4_Production_RPA_Maintenance-Lights-On-Incident.png";
import RPA_Support_Section_4_RPA_Enhancement_Services_Enhancements_Process_Development from "../../images/manage-rpa/RPA_Support_Section_4_RPA_Enhancement_Services-Enhancements-Process-Development.png";
import RPA_Support_Section_4_RPA_Maintenance_Patches_on_premise_only from "../../images/manage-rpa/RPA_Support_Section_4_RPA_Maintenance_Patches_(on-premise-only).png";
import RPA_Support_Section_4_RPA_Maintenance_Production_Support from "../../images/manage-rpa/RPA_Support_Section_4_RPA_Maintenance-Production_Support.png";
import RPA_Managed_Services_Can_Reduce from "../../images/manage-rpa/RPA-Managed-Services-Can-Reduce.jpg";
import Why_Your_Organization_Needs_RPA from "../../images/manage-rpa/Why-Your-Organization-Needs-RPA.jpg";
import rpaIcon from "../../images/manage-rpa/icon.png";
//styles
// import layoutStyle from "../../components/layouts/layout.module.scss";
import s from "./rpa-managed-support-services.module.scss";
import { mergeRight, partialRight } from "ramda";

export const BlogSubscribeFormReplacementMessage = () => (
  <div
    className={contactStyle.thanks}
    style={{
      justifyContent: "center",
      alignItems: "center",
      alignSelf: "center"
    }}
  >
    <h5
      style={{
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        alignSelf: "center"
      }}
    >
      Thank You!
    </h5>
    <p style={{ textAlign: "center" }}>
      We will reach out to you soon regarding your quote.
    </p>
  </div>
  // <div className={contactStyle.thanks}>
  //   <p>
  //     <span>Done!</span>Thank you so much for your subscription.
  //   </p>
  // </div>
);

const RpaManagedSupportServices = ({ location, data }) => {
  // const {
  //   SEO: seo,
  //   hero,
  //   overview_description,
  //   it_overview_image,
  //   solutions,
  //   nitco_difference
  // } = data.strapiPsIt;

  useSetPageData(location);

  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);

  const showFormOverlay = value => {
    let form = document.querySelector("#hubSpotFormOverlay");
    if (value) {
      form.style.display = "block";
    } else {
      form.style.display = "none";
    }
  };

  return (
    <>
      <SEO
        {...{
          title: `RPA Managed Support Services | Services`,
          description:
            "Managed Services Provider for Support and Maintenance of Robotic Process Automation (RPA) | NITCO Inc."
        }}
      />

      <Hero
        heading={"RPA Managed Services"}
        iconComponent={<img src={rpaIcon} />}
        // icon={hero[0].service_icon.childImageSharp.fixed}
        // videoImage={hero[0].services_main_image.childImageSharp.fixed.src}
        // videoLink={"/videos/final-consulting.mp4"}
      />
      <ComponentHeader
        rootClassName={`bg-light text-primary`}
        text={`Managed Services Provider for Support and Maintenance of RPA`}
      />
      {/* <div style={{ marginTop: "40px" }}></div> */}
      <SwitchComponent
        evenClassName={`bg-light text-primary pt-5 pb-5`}
        oddClassName={`bg-light text-primary pt-5 pb-5`}
        contents={[
          {
            heading:
              "RPA Managed Services Can Reduce IT Workload for Support and Maintenance",
            image: <img src={RPA_Managed_Services_Can_Reduce} />,
            // image: <Image fluid={it_overview_image.childImageSharp.fluid} />,
            content: `Think of Robotic Process Automation, or RPA, as your personal virtual robot--designed to help you perform a wide range of business functions. RPA frees up your staff so they can focus on higher-level, more strategic, duties.    
            That is, of course, if managing your RPA solutions doesn’t end up eating up a big chunk of those time and expense savings! 
            Outsourcing your RPA support and maintenance to NITCO Inc., can be a great way to reduce your IT department’s workload demands.  
            `
            // <ReactMarkdown
            //   source={overview_description}
            //   escapeHtml={false}
            //   renderers={{ link: LinkRenderer }}
            // />
          },
          {
            heading: "Why Your Organization Needs RPA Services",
            image: <img src={Why_Your_Organization_Needs_RPA} />,
            // image: <Image fluid={it_overview_image.childImageSharp.fluid} />,
            content: (
              <p>
                Your IT staff already have a lot of work on their plates.
                Managing the overall information infrastructure of the business.
                Managing security threats. All while also being tasked with
                planning for and scaling the organization’s future growth and
                leveraging the IT function as a core competency.
                <br /> <br />
                Routine support and maintenance of automated processes is not a
                great use of your valuable resource’s time. That is where we
                come in with the expertise and experience to support you and
                your organization.
                <br /> <br />
                At NITCO, we believe supporting your automation needs is a great
                strategic use of your IT team. Keeping your automations running
                smoothly, efficiently, and reliably is our job. NITCO’s RPA
                managed IT services can provide:
                <br />
              </p>
            )
            // <ReactMarkdown
            //   source={overview_description}
            //   escapeHtml={false}
            //   renderers={{ link: LinkRenderer }}
            // />
          }
        ]}
      />

      {/* <div className={'row'}>
        <div className={'col'}>
          Test Text 1
        </div>
        <div className={'col'}>
          Test Text 2
        </div>

      </div> */}

      <PanelFoundation rootClassName={`bg-light text-primary pt-0 pb-5`}>
        <div className={"table"}>
          <div className={"row"}>
            <div className={"col"}>
              <Col sm={12}>
                <ul className={`one-column`}>
                  <li>
                    Proactive monitoring of RPA tools and automated processes
                  </li>
                  <li>Defined Service Level Agreements (SLAs)</li>
                  <li>Business hours only or 24/7/365 customer support</li>
                  <li>
                    On-boarding and support for RPA developers and Citizen
                    Developers
                  </li>
                </ul>
              </Col>
            </div>
            <div className={"col"}>
              {/* <Col sm={6}> */}
              <ul className={`one-column`}>
                <li>Automation optimization</li>
                <li>Consistent and transparent issue tracking</li>
                <li>Scalable pricing</li>
                <li>Enhancement development for existing automations</li>
              </ul>
              {/* </Col> */}
            </div>
          </div>
        </div>
      </PanelFoundation>

      {/* <ListItem
        rootClassName={`bg-primary text-light ${s.iconLeftCard}`}
        // rootClassName={s.purpleBackground}
        columnsPerRow={3}
        isCard
        shouldRenderLeft

        // hasCircle
        // circleBorderColor={`var(--primary)`}
        contents={
          [
            {
              image: <img src={RPA_Support_Section_1_Proactive_monitoring} />,
              content: <h6 className={`${s.section1Text}`}>Proactive monitoring of RPA tools and automated processes</h6>,
            },
            {
              image: <img src={RPA_Support_Section_1_Defined_Service_Level} />,
              content: <h6 className={`${s.section1Text}`}>Defined Service Level Agreements (SLAs)</h6>,
            },

            {
              image: <img src={RPA_Support_Section_1_Business_hours_only_or_24_7_365} />,
              content: <h6 className={`${s.section1Text}`}>Business hours only or 24/7/365 customer support</h6>,
            },

            {
              image: <img src={RPA_Support_Section_1_On_boarding_and_support_for_RPA} />,
              content: <h6 className={`${s.section1Text}`}>On-boarding and support for RPA developers and Citizen Developers</h6>,
            },

            {
              image: <img src={RPA_Support_Section_1_Automation_optimization} />,
              content: <h6 className={`${s.section1Text}`}>Automation optimization</h6>,
            },

            {
              image: <img src={RPA_Support_Section_1_Consistent_and_transparent} />,
              content: <h6 className={`${s.section1Text}`}>Consistent and transparent issue tracking</h6>,
            },
            {
              image: <img src={RPA_Support_Section_1_Scalable_pricing} />,
              content: <h6 className={`${s.section1Text}`}>Scalable pricing</h6>,
            },
            {
              image: <img src={RPA_Support_Section_1_Enhancement_development} />,
              content: <h6 className={`${s.section1Text}`}>Enhancement development for existing automations</h6>,
            }

          ]
        }
      /> */}

      <ComponentHeader
        rootClassName={`bg-white`}
        textClassName={`text-primary`}
        text={"What Is Robotic Process Automation (RPA) Managed Services?"}
      />
      <PanelFoundation>
        <Col sm={12}>
          <p>
            RPA managed services represents a suite of service offerings
            available to manage and maintain your RPA tools and automations.
            These include:
          </p>
        </Col>
      </PanelFoundation>

      <ListItem
        rootClassName={`pb-5`}
        columnsPerRow={2}
        shouldRenderLeft
        // hasCircle
        // circleBorderColor={`var(--primary)`}
        contents={[
          {
            image: (
              <img
                src={
                  RPA_Support_Section_2_Processes_for_reporting_issues_and_tracking
                }
                style={{ width: "75px" }}
              />
            ),
            content: `Processes for reporting issues and tracking the progress of issue resolution`
            // heading: "Heading",
          },
          {
            image: (
              <img
                src={
                  RPA_Support_Section_2_Customer_portal_to_support_and_maintenance
                }
                style={{ width: "75px" }}
              />
            ),
            content: `Customer portal to support and maintenance dashboard.`
            // heading: "Heading",
          },

          {
            image: (
              <img
                src={
                  RPA_Support_Section_2_Monitoring_your_automations_for_errors
                }
                style={{ width: "75px" }}
              />
            ),
            content: `Monitoring your automations for errors, exceptions and availability`
            // heading: "Heading",
          },

          {
            image: (
              <img
                src={RPA_Support_Section_2_Working_collaboratively_to_scale_up}
                style={{ width: "75px" }}
              />
            ),
            content: `Working collaboratively to scale up the use of automated processes`
            // heading: "Heading",
          }
        ]}
      />

      <ComponentHeader
        rootClassName={`bg-white`}
        textClassName={`text-primary`}
        text={"On-Prem and RPA Cloud Automation Support and Maintenance"}
      />
      <PanelFoundation rootClassName={`panel-padding pb-0 pt-1`}>
        <Col sm={12}>
          <p>
            NITCO’s experienced teams can provide RPA managed support and
            maintenance for both an on-prem or cloud Implemented solution.
          </p>
        </Col>
      </PanelFoundation>

      <ListItem
        rootClassName={s.newCard}
        itemStyle={{ textAlign: "left" }}
        columnsPerRow={2}
        isCard
        headingContainerClassName={`${s.newCardImg}`}
        // hasCircle
        // circleBorderColor={`var(--primary)`}
        contents={[
          {
            image: (
              <img
                src={RPA_Support_Section_3_On_Prem}
                style={{ width: "100px" }}
              />
            ),
            heading: "On-Prem",
            content:
              "In an on-prem environment, your company hosts its RPA applications on its own hardware, and NITCO can provide support via VPN access to your company’s network."
          },
          {
            image: (
              <img
                src={RPA_Support_Section_3_Cloud}
                style={{ width: "100px" }}
              />
            ),
            heading: "Cloud",
            content:
              "With your cloud platform, the RPA applications are hosted on your internal cloud servers and managed by NITCO with the appropriate secure access."
          }
        ]}
      />

      <ComponentHeader
        rootClassName={`bg-primary`}
        textClassName={`text-light`}
        text={"Why Choose NITCO for RPA Managed Services?"}
      />
      <PanelFoundation rootClassName={`panel-padding bg-light pb-0`}>
        <Col sm={12}>
          <p>
            Selecting NITCO as your RPA managed services partner allows you to
            delegate the day-to-day support and maintenance of your RPA solution
            and automated processes, so your IT teams can focus on more
            important work. As an established RPA vendor, NITCO can offer
            greater reliability and availability than most companies find they
            can attain managing their RPA tools and automations on their own.
            <br />
            <br />
            Our consistent and customer-focused approach to issue tracking and
            resolution also means your end users can count on the automations
            they need to maximize their productivity and effectiveness.
          </p>
        </Col>
      </PanelFoundation>

      <ComponentHeader
        // rootClassName={`bg-light`}
        textClassName={`text-primary`}
        text={"How Our RPA Support and Maintenance Services Work"}
      />
      <PanelFoundation rootClassName={`panel-padding pb-0`}>
        <Col sm={12}>
          <p>
            NITCO’s support and maintenance services are clearly outlined in our
            Statements of Work (SOWs) and Service Level Agreements (SLAs). You
            know exactly what you are getting for your investment and have the
            information you need to ensure you’re getting the level of service
            and support you deserve. Our NITCO Inc RPA Support and Maintenace
            services fall into four key areas:
          </p>
        </Col>
      </PanelFoundation>

      <ListItem2
        rootClassName={`${s.purpleBackground} pb-0`}
        columnsPerRow={2}
        isCard
        hasCircle
        circleBorderColor={`var(--primary)`}
        circleColor={`var(--primary)`}
        contents={[
          {
            image: (
              <img
                src={
                  RPA_Support_Section_4_Production_RPA_Maintenance_Lights_On_Incident
                }
                style={{ width: "40px" }}
              />
            ),
            heading: `Production RPA Maintenance/Lights-On Incident Support`,
            content: `We monitor and respond to incidents, correct errors and work with you to improve performance and reliability—adding, changing or removing functionality as necessary to support your applications.`
          },
          {
            image: (
              <img
                src={RPA_Support_Section_4_RPA_Maintenance_Production_Support}
                style={{ width: "40px" }}
              />
            ),
            heading: `RPA Maintenance / Production Support`,
            content: `We provide ongoing operation of your supported applications for both cyclical and non-cyclical activities. `
          },

          {
            image: (
              <img
                src={
                  RPA_Support_Section_4_RPA_Maintenance_Patches_on_premise_only
                }
                style={{ width: "40px" }}
              />
            ),
            heading: `RPA Maintenance Patches (on-premise only)`,
            content: `We’ll apply patches to maintain system stability and fix issues when not in conflict with our support service or service level agreements.`
          },

          {
            image: (
              <img
                src={
                  RPA_Support_Section_4_RPA_Enhancement_Services_Enhancements_Process_Development
                }
                style={{ width: "40px" }}
              />
            ),
            heading: `RPA Enhancement Services: Enhancements/Process Development `,
            content: `We modify automated process to respond to changing or new business requirements.`
          }
        ]}
      />
      <PanelFoundation rootClassName={`panel-padding`}>
        <Col sm={12}>
          <p>
            Each client’s need is unique, so we work with you up front to
            identify how we can best support you for the short and long term.
            After discussing your specific needs, NITCO will work with your
            staff to outline the specific requirements of our engagement in a
            SOW that documents:
          </p>
          <ul className={`pb-3 pt-3`}>
            <li>The specific services you’ll receive</li>
            <li>Who’s responsible for what—and when</li>
            <li>Expected Deliverables</li>
            <li>Monthly support and maintenance costs</li>
          </ul>
          <p>
            That’s the starting point, of course. NITCO understands that your
            needs may change as you grow and as the you scale up your automation
            practice. Our change process is flexible and agile enough to support
            your evolving needs.
          </p>
        </Col>
      </PanelFoundation>

      <ComponentHeader
        rootClassName={`pt-0`}
        textClassName={`text-primary`}
        text={"Engage with NITCO’s RPA Team Today"}
      />
      <PanelFoundation rootClassName={`panel-padding pb-0`}>
        <Col sm={12}>
          {/* <h3>
            Engage with NITCO’s RPA Team Today
          </h3> */}
          <p>
            NITCO is an industry leader with years of experience assisting
            companies large and small with their RPA managed support and
            maintenance needs. Contact us today to discuss your RPA management
            needs and to receive a free estimate.
          </p>
        </Col>
      </PanelFoundation>

      {/* 
      <div className="pricing pricing-palden">
        <div className="pricing-item">
          <div className="pricing-deco">
            <div className="pricing-price">

              <h5 className="pricing-title">On-Demand</h5>
            </div>
          </div>
          <ul className="pricing-feature-list">
            <li className="pricing-feature"><i className={`fas fa-times text-danger mr-3`}></i>24/7 Anytime Support</li>
            <li className="pricing-feature"><i className={`fas fa-times text-danger mr-3`}></i>20 Develop Hours Per Month</li>
            <li className="pricing-feature"><i className={`fas fa-check-circle text-success mr-3`}></i>Hyper-Care <small><em>(2 weeks courtesy support)</em></small></li>
            <li className="pricing-feature"><i className={`fas fa-check-circle text-success mr-3`}></i>Maintenance Patches and Updates</li>
            <li className="pricing-feature"><i className={`fas fa-check-circle text-success mr-3`}></i>Citizen Developer</li>
          </ul>
          <button className="pricing-action">Request a Quote</button>
        </div>
        <div className="pricing-item pricing__item--featured">
          <div className="pricing-deco bg-primary">
            <div className="pricing-price">
              <h4 className="pricing-title">Standard</h4>
            </div>
          </div>
          <ul className="pricing-feature-list">
            <li className="pricing-feature"><i className={`fas fa-times text-danger mr-3`}></i>24/7 Anytime Support</li>
            <li className="pricing-feature"><i className={`fas fa-check-circle text-success mr-3`}></i>20 Develop Hours Per Month</li>
            <li className="pricing-feature"><i className={`fas fa-check-circle text-success mr-3`}></i>Hyper-Care <small><em>(2 weeks courtesy support)</em></small></li>
            <li className="pricing-feature"><i className={`fas fa-check-circle text-success mr-3`}></i>Maintenance Patches and Updates</li>
            <li className="pricing-feature"><i className={`fas fa-check-circle text-success mr-3`}></i>Citizen Developer</li>
          </ul>
          <button className="pricing-action">Request a Quote</button>
        </div>
        <div className="pricing-item">
          <div className="pricing-deco">

            <div className="pricing-price">

              <h5 className="pricing-title">Premium</h5>
            </div>
            
          </div>
          <ul className="pricing-feature-list">
            <li className="pricing-feature"><i className={`fas fa-check-circle text-success mr-3`}></i>24/7 Anytime Support</li>
            <li className="pricing-feature"><i className={`fas fa-check-circle text-success mr-3`}></i>20 Develop Hours Per Month</li>
            <li className="pricing-feature"><i className={`fas fa-check-circle text-success mr-3`}></i>Hyper-Care <small><em>(2 weeks courtesy support)</em></small></li>
            <li className="pricing-feature"><i className={`fas fa-check-circle text-success mr-3`}></i>Maintenance Patches and Updates</li>
            <li className="pricing-feature"><i className={`fas fa-check-circle text-success mr-3`}></i>Citizen Developer</li>
          </ul>
          <button className="pricing-action">Request a Quote</button>
        </div>
      </div>




      <br />
      <br />
      <br /> */}

      <div className="pricing pricing-palden">
        <div className="pricing-item">
          <div className="pricing-deco">
            <div className="pricing-price">
              <h5 className="pricing-title">Premium</h5>
            </div>
          </div>
          <p style={{ width: "80%", margin: "1rem auto" }}>
            <strong>24/7/365 days</strong>
          </p>
          <ul className="pricing-feature-list">
            <li className="pricing-feature">
              <i className={`fas fa-check-circle text-success mr-3`}></i>Client
              Portal/Dashboard
            </li>
            <li className="pricing-feature">
              <i className={`fas fa-check-circle text-success mr-3`}></i>
              Hyper-Care (10 days complimentary, each new
              automation/enhancement)
            </li>
            <li className="pricing-feature">
              <i className={`fas fa-check-circle text-success mr-3`}></i>
              Proactive Monitoring
            </li>
            <li className="pricing-feature">
              <i className={`fas fa-check-circle text-success mr-3`}></i>
              Production RPA Maintenance / Lights-On Incident Support.
            </li>
            <li className="pricing-feature">
              <i className={`fas fa-check-circle text-success mr-3`}></i>RPA
              Maintenance / Production Support
            </li>
            <li className="pricing-feature">
              <i className={`fas fa-check-circle text-success mr-3`}></i>RPA
              Maintenance Patches
            </li>
            <li className="pricing-feature">
              <i className={`fas fa-check-circle text-success mr-3`}></i>Citizen
              Developer On-boarding & Support (Add on available)
            </li>
            <li className="pricing-feature">
              <i className={`fas fa-check-circle text-success mr-3`}></i>
              Enhancements/Process Development (20 hours per Month included)
            </li>
          </ul>
          <button
            className="pricing-action"
            onClick={() => showFormOverlay(true)}
          >
            Request a Quote
          </button>
        </div>
        <div className="pricing-item pricing__item--featured">
          <div className="pricing-deco bg-primary">
            <div className="pricing-price">
              <h4 className="pricing-title">Standard</h4>
            </div>
          </div>
          <p style={{ width: "80%", margin: "1rem auto" }}>
            <strong>
              US Office Hours, 8a-5p CST,{" "}
              <span style={{ whiteSpace: "nowrap" }}>No Holidays</span>
            </strong>
          </p>
          <ul className="pricing-feature-list">
            <li className="pricing-feature">
              <i className={`fas fa-check-circle text-success mr-3`}></i>Client
              Portal/Dashboard
            </li>
            <li className="pricing-feature">
              <i className={`fas fa-check-circle text-success mr-3`}></i>
              Hyper-Care (10 days complimentary, each new
              automation/enhancement)
            </li>
            <li className="pricing-feature">
              <i className={`fas fa-check-circle text-success mr-3`}></i>
              Proactive Monitoring
            </li>
            <li className="pricing-feature">
              <i className={`fas fa-check-circle text-success mr-3`}></i>
              Production RPA Maintenance / Lights-On Incident Support.
            </li>
            <li className="pricing-feature">
              <i className={`fas fa-check-circle text-success mr-3`}></i>RPA
              Maintenance / Production Support
            </li>
            <li className="pricing-feature">
              <i className={`fas fa-check-circle text-success mr-3`}></i>RPA
              Maintenance Patches
            </li>
            <li className="pricing-feature">
              <i className={`fas fa-check-circle text-success mr-3`}></i>Citizen
              Developer On-boarding & Support (Add on available)
            </li>
            {/* <li className="pricing-feature"><i className={`fas fa-times text-danger mr-3`}></i>Enhancements/Process Development (20 hours per Month included)</li> */}
            {/* <li className="pricing-feature"><i className={`fas fa-times text-danger mr-3`}></i>Weekly Client Status Meetings</li>
            <li className="pricing-feature"><i className={`fas fa-check-circle text-success mr-3`}></i>Maintenance Patches and Updates</li>
            <li className="pricing-feature"><i className={`fas fa-check-circle text-success mr-3`}></i>Defined Service Level Agreements with Escalation Expectations</li> */}
          </ul>
          <button
            className="pricing-action"
            onClick={() => showFormOverlay(true)}
          >
            Request a Quote
          </button>
        </div>
        <div className="pricing-item">
          <div className="pricing-deco">
            <div className="pricing-price">
              <h5 className="pricing-title">On-Demand</h5>
            </div>
          </div>
          <p style={{ width: "80%", margin: "1rem auto" }}>
            <strong>
              US Office Hours, 8a-5p CST,{" "}
              <span style={{ whiteSpace: "nowrap" }}>No Holidays</span>
            </strong>
          </p>
          <ul className="pricing-feature-list">
            <li className="pricing-feature">
              <i className={`fas fa-check-circle text-success mr-3`}></i>Client
              Portal/Dashboard
            </li>
            <li className="pricing-feature">
              <i className={`fas fa-check-circle text-success mr-3`}></i>
              Hyper-Care (10 days complimentary, each new
              automation/enhancement)
            </li>
            {/* <li className="pricing-feature"><i className={`fas fa-times text-danger mr-3`}></i>Proactive Monitoring</li>
            <li className="pricing-feature"><i className={`fas fa-times text-danger mr-3`}></i>Production RPA Maintenance / Lights-On Incident Support.</li>
            <li className="pricing-feature"><i className={`fas fa-times text-danger mr-3`}></i>RPA Maintenance / Production Support</li>
            <li className="pricing-feature"><i className={`fas fa-times text-danger mr-3`}></i>RPA Maintenance Patches</li>
            <li className="pricing-feature"><i className={`fas fa-times text-danger mr-3`}></i>Citizen Developer On-boarding & Support  (Add on available)</li>
            <li className="pricing-feature"><i className={`fas fa-times text-danger mr-3`}></i>Enhancements/Process Development (20 hours per Month included)</li> */}
            {/* <li className="pricing-feature"><i className={`fas fa-times text-danger mr-3`}></i>Enhancements/Process Development (20 hours per Month included)</li>
            <li className="pricing-feature"><i className={`fas fa-times text-danger mr-3`}></i>Weekly Client Status Meetings</li>
            <li className="pricing-feature"><i className={`fas fa-times text-danger mr-3`}></i>Maintenance Patches and Updates</li>
            <li className="pricing-feature"><i className={`fas fa-check-circle text-success mr-3`}></i>Defined Service Level Agreements with Escalation Expectations</li> */}
          </ul>
          <button
            className="pricing-action"
            onClick={() => showFormOverlay(true)}
          >
            Request a Quote
          </button>
        </div>
      </div>

      <br />
      <br />
      <br />

      {/* <div className="pricing pricing-palden">
        <div className="pricing-item">
          <div className="pricing-deco">
            <div className="pricing-price">

              <h5 className="pricing-title">On-Demand</h5>
            </div>
          </div>
          <ul className="pricing-feature-list">
            <li className="pricing-feature"><i className={`fas fa-check-circle text-success mr-3`}></i>Client Portal/Dashboard</li>
            <li className="pricing-feature"><i className={`fas fa-check-circle text-success mr-3`}></i>Citizen Developer On-boarding & Support  (Add on available)</li>
            <li className="pricing-feature"><i className={`fas fa-check-circle text-success mr-3`}></i>Process Automation – Robot Design, Development, Testing and Deployment (Add on available)</li>
            <li className="pricing-feature"><i className={`fas fa-check-circle text-success mr-3`}></i>Hyper-Care (10 days complimentary, each new automation/enhancement)</li>
            
            <li className="pricing-feature"><i className={`fas fa-times text-danger mr-3`}></i>Proactive Monitoring </li>
            <li className="pricing-feature"><i className={`fas fa-times text-danger mr-3`}></i>Production RPA Maintenance / Lights-On Incident Support.</li>
            <li className="pricing-feature"><i className={`fas fa-times text-danger mr-3`}></i>RPA Maintenance / Production Support.</li>
            <li className="pricing-feature"><i className={`fas fa-times text-danger mr-3`}></i>RPA Maintenance Patches</li>
            <li className="pricing-feature"><i className={`fas fa-times text-danger mr-3`}></i>Enhancements/Process Development (20 hours per Month included)</li>
          </ul>
          <button className="pricing-action" onClick={() => handleShowModal()}>Request a Quote</button>
        </div>
        <div className="pricing-item pricing__item--featured">
          <div className="pricing-deco bg-primary">
            <div className="pricing-price">

              <h4 className="pricing-title">Standard</h4>
            </div>
            <h3 className="pricing-title">Business</h3>
          </div>
          <ul className="pricing-feature-list" style={{ marginTop: "16px" }}>
            <li className="pricing-feature"><i className={`fas fa-check-circle text-success mr-3`}></i>Client Portal/Dashboard</li>
            <li className="pricing-feature"><i className={`fas fa-check-circle text-success mr-3`}></i>Citizen Developer On-boarding & Support  (Add on available)</li>
            <li className="pricing-feature"><i className={`fas fa-check-circle text-success mr-3`}></i>Process Automation – Robot Design, Development, Testing and Deployment (Add on available)</li>
            <li className="pricing-feature"><i className={`fas fa-check-circle text-success mr-3`}></i>Proactive Monitoring </li>
            <li className="pricing-feature"><i className={`fas fa-check-circle text-success mr-3`}></i>RPA Maintenance Patches</li>
            <li className="pricing-feature"><i className={`fas fa-check-circle text-success mr-3`}></i>RPA Maintenance / Production Support.</li>
            <li className="pricing-feature"><i className={`fas fa-check-circle text-success mr-3`}></i>Production RPA Maintenance / Lights-On Incident Support.</li>
            <li className="pricing-feature"><i className={`fas fa-check-circle text-success mr-3`}></i>Hyper-Care (10 days complimentary, each new automation/enhancement)</li>
            
            <li className="pricing-feature"><i className={`fas fa-times text-danger mr-3`}></i>Enhancements/Process Development (20 hours per Month included)</li>
          </ul>
          <button className="pricing-action" onClick={() => handleShowModal()}>Request a Quote</button>
        </div>
        <div className="pricing-item">
          <div className="pricing-deco">

            <div className="pricing-price">

              <h5 className="pricing-title">Premium</h5>
            </div>

          </div>
          <ul className="pricing-feature-list ml-2">
            <li className="pricing-feature"><i className={`fas fa-check-circle text-success mr-3`}></i>Client Portal/Dashboard</li>
            <li className="pricing-feature"><i className={`fas fa-check-circle text-success mr-3`}></i>Citizen Developer On-boarding & Support  (Add on available)</li>
            <li className="pricing-feature"><i className={`fas fa-check-circle text-success mr-3`}></i>Process Automation – Robot Design, Development, Testing and Deployment (20 hours included per month)</li>
            <li className="pricing-feature"><i className={`fas fa-check-circle text-success mr-3`}></i>Proactive Monitoring </li>
            <li className="pricing-feature"><i className={`fas fa-check-circle text-success mr-3`}></i>RPA Maintenance Patches</li>
            <li className="pricing-feature"><i className={`fas fa-check-circle text-success mr-3`}></i>RPA Maintenance / Production Support.</li>
            <li className="pricing-feature"><i className={`fas fa-check-circle text-success mr-3`}></i>Production RPA Maintenance / Lights-On Incident Support.</li>
            <li className="pricing-feature"><i className={`fas fa-check-circle text-success mr-3`}></i>Hyper-Care (10 days complimentary, each new automation/enhancement)</li>
          </ul>
          <button className="pricing-action" onClick={() => handleShowModal()}>Request a Quote</button>
        </div>
      </div> */}

      {/* <ul className={`two-columns`}>
                <li>Proactive monitoring of RPA tools and automated processes</li>
                <li>Defined Service Level Agreements (SLAs)</li>
                <li>Business hours only or 24/7/365 customer support</li>
                <li>On-boarding and support for RPA developers and Citizen Developers</li>
                <li>Automation optimization</li>
                <li>Consistent and transparent issue tracking</li>
                <li>Scalable pricing</li>
                <li>Enhancement development for existing automations</li>
              </ul> */}
      {/* <ComponentHeader
        rootClassName={`bg-white`}
        textClassName={`text-primary`}
        text={"Solutions"}
      />
      <SwitchComponent
        evenClassName={`bg-primary text-white`}
        oddClassName={`bg-white text-dark`}
        // evenBgColor={"purple"}
        // evenTextColor={"white"}
        // oddBgColor={"white"}
        // oddTextcolor={"gray"}
        contents={solutions.map((sol, i) => ({
          image: <Image fluid={sol.row_switch_image.childImageSharp.fluid} />,
          heading: sol.heading,
          content: (
            <ReactMarkdown
              source={sol.content}
              escapeHtml={false}
              renderers={{ link: LinkRenderer }}
            />
          )
        }))}
      />

      <ComponentHeader
        rootClassName={"bg-light"}
        textClassName={"text-primary"}
        text={"The NITCO Difference"}
        id={"nitco-difference"}
      />
      <PanelFoundation rootClassName={`panel-padding`}>
        <Col sm={12}>
          <ul className={`${s.smileIconList}`}>
            {nitco_difference.map((nitco, i) => (
              <li key={i}>
                <div>
                  <h4>{nitco.heading}</h4>
                  <div>
                    <p>{nitco.content}</p>
                  </div>
                </div>
                <div
                  className={`${s.iconListConnector}`}
                  style={
                    i === nitco_difference.length - 1 ? { display: "none" } : {}
                  }
                />
              </li>
            ))}
          </ul>
        </Col>
      </PanelFoundation> */}

      <div id="hubSpotFormOverlay" className={`${s.hubSpotFormOverlay}`}>
        <div className={`${s.hubSpotFormOverlayDiv}`}>
          <div className={`${s.hubSpotFormHeader}`}>
            <span className={`${s.hubSpotFormHeaderDescription}`}>
              Request a new Quote
            </span>
            <span
              className={`${s.hubSpotFormOverlayCloseButton}`}
              onClick={() => {
                showFormOverlay(false);
              }}
            >
              X
            </span>
          </div>
          <HubSpotForm
            formId="c052d59c-c46f-4821-a9d7-a3b5e73f68cb"
            replacementElement={
              <div
                className={"container"}
                style={{ justifyContent: "center", alignItems: "center" }}
              >
                <div
                  className={`container`}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <BlogSubscribeFormReplacementMessage />
                </div>
                <div
                  className={"container"}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                  }}
                >
                  <button
                    className="btn btn-primary ml-4"
                    onClick={() => {
                      showFormOverlay(false);
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
            }
          />
        </div>
      </div>

      {/* <Modal
        show={showModal}
        onHide={handleCloseModal}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Request A Quote</Modal.Title>
        </Modal.Header>

        <Modal.Body>
        <HubSpotForm
            formId="d0f017e3-30cf-4cf7-98ea-b2b1fce2eec8"
            replacementElement={
              <div className={'container'} style={{justifyContent: "center",  alignItems: "center"}}>
                <div className={`container` } style={{display: "flex", justifyContent: "center",  alignItems: "center",}}>
                  <BlogSubscribeFormReplacementMessage/>
                </div>
                <div className={'container'}  style={{display: "flex", justifyContent: "center",  alignItems: "center"}}>
                  <button className="btn btn-primary ml-4" onClick={() => {setShowModal(false)}}>Close</button>
                </div>
              </div>
            }
          />

        </Modal.Body> */}

      {/* <Modal.Footer>
          <Button variant="secondary">Close</Button>
          <Button variant="primary">Save changes</Button>
        </Modal.Footer> */}
      {/* </Modal> */}
    </>
  );
};

export default RpaManagedSupportServices;

// export const pageQuery = graphql`
//   {
//     strapiPsIt {
//       SEO {
//         description
//       }
//       hero {
//         main_heading
//         main_subheading
//         services_main_image {
//           childImageSharp {
//             fixed(width: 250) {
//               ...GatsbyImageSharpFixed
//             }
//           }
//         }
//         service_icon {
//           childImageSharp {
//             fixed(width: 100, height: 100) {
//               ...GatsbyImageSharpFixed
//             }
//           }
//         }
//       }
//       overview_description
//       it_overview_image {
//         childImageSharp {
//           fluid {
//             ...GatsbyImageSharpFluid
//           }
//         }
//       }
//       versatile {
//         heading
//         content
//         card_image {
//           childImageSharp {
//             fixed(width: 100) {
//               ...GatsbyImageSharpFixed
//             }
//           }
//         }
//       }
//       solutions {
//         heading
//         content
//         row_switch_image {
//           childImageSharp {
//             fluid {
//               ...GatsbyImageSharpFluid
//             }
//           }
//         }
//       }
//       nitco_difference {
//         heading
//         content
//       }
//     }
//   }
// `;

// quality {
//   heading
//   content
//   card_image {
//     childImageSharp {
//       fixed(width: 100) {
//         ...GatsbyImageSharpFixed
//       }
//     }
//   }
// }
